import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { Divider } from 'rsuite';
import '../../node_modules/rsuite/dist/rsuite.min.css';

const FooterContainer=styled.div`
width: 100%;
position: absolute;
bottom: 0;
padding-top: 40px;
// display: flex;
// flex-direction: column;
height: 40px;
align-self: center;

ul {
    display: flex;
    justify-content: center;
}
li {
    padding: 3px;
}
`



const Footer = () => {
    return (
        <FooterContainer>
			<ul>
				<li><Link to="/Info">About YellowRay Consulting</Link></li>
                <Divider vertical />
				<li><a target="blank" href="http://yrcgi.com/">yellowray.ca</a></li>
                <Divider vertical />
                <b style={{ color: "green" }}></b>
				<li><a target="blank" href="http://yrcgi.com/contact-us.html">Contact Us</a></li>
                <Divider vertical />
				<li><Link to="/">Home Page</Link></li>
			</ul>
			<ul>
				<li>Last Updated: January 3rd 2025</li>
                <Divider vertical />
				<li>&copy; 2023-{new Date().getFullYear()} Yellow Ray Consulting Group Inc. All Rights Reserved.</li>
                <Divider vertical />
				<li><Link to="/Terms">Terms of Use</Link></li>
			</ul>
		</FooterContainer>
    )
};

export default Footer;