import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';


const SheetContainer=styled.section`
    width: 98%;
    border-top: 4px solid yellow;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 40px;
    margin: 20px;
    background-color: white;
    h1 {
        font-size: 40px;
        text-align: center;
        margin-bottom: 40px
    }
    ol, li {
        list-style-type: number;
        line-height: 45px;
        font-size: 20px;
        padding: 10px;
        margin: 20px;
    }
    mark {
        background-color: black;
        border-radius: 6px;
        color: white;
        padding: 7px;
        font-style: italic;
      }
`

const Mount = () => {
  return <>
  <SheetContainer>
  <h1>Mounting a Drive on a Linux Server</h1>

<h2>General Steps for Mounting a Drive</h2>
<ol>
  <li>
    <strong>Physically Connect the Drive:</strong> Ensure the drive is properly connected and powered on.
  </li>
  <li>
    <strong>Identify the New Drive:</strong> Use <code>lsblk</code> or <code>fdisk -l</code> to list block devices and partitions. <br />
    Example:
    <pre><code>
lsblk
fdisk -l
    </code></pre>
    The new drive will typically appear as <code>/dev/sdX</code>, where <code>X</code> is a letter corresponding to the drive.
  </li>
  <li>
    <strong>Partition and Format the Drive:</strong> Use the following command to create an ext4 file system: <br />
    <pre><code>sudo mkfs.ext4 -L DriveName /dev/sdX</code></pre>
    Replace <code>/dev/sdX</code> with the appropriate drive identifier.
  </li>
  <li>
    <strong>Create a Mount Point:</strong> Choose or create a directory for mounting:
    <pre><code>sudo mkdir /mnt/newdrive</code></pre>
  </li>
  <li>
    <strong>Mount the Drive:</strong> Mount the drive to the chosen mount point:
    <pre><code>sudo mount /dev/sdX /mnt/newdrive</code></pre>
  </li>
  <li>
    <strong>Make the Mount Persistent:</strong> Add the drive to <code>/etc/fstab</code> to mount it automatically at boot. Example entry:
    <pre><code>/dev/sdX /mnt/newdrive ext4 defaults 0 0</code></pre>
    Use UUID for better reliability:
    <pre><code>
UUID=$(blkid -s UUID -o value /dev/sdX)
echo "UUID=$(blkid -s UUID -o value /dev/sdX) /mnt/newdrive ext4 defaults 0 0" | sudo tee -a /etc/fstab
    </code></pre>
  </li>
</ol>

<h2>Handling Large Drives (&gt;2 TiB)</h2>
<p>If the drive is larger than 2 TiB, you'll need to use a <strong>GUID Partition Table (GPT)</strong>. Follow these steps:</p>
<ol>
  <li>
    <strong>Launch <code>fdisk</code> for the Drive:</strong>
    <pre><code>sudo fdisk /dev/sdX</code></pre>
    If the drive size exceeds 2 TiB, <code>fdisk</code> will prompt you to use GPT.
  </li>
  <li>
    <strong>Create a GPT Partition Table:</strong> In the <code>fdisk</code> menu, type:
    <pre><code>g</code></pre>
    This switches to GPT.
  </li>
  <li>
    <strong>Create a New Partition:</strong> Type:
    <pre><code>n</code></pre>
    Accept the defaults to create a single partition using the entire disk.
  </li>
  <li>
    <strong>Write Changes to Disk:</strong> Type:
    <pre><code>w</code></pre>
  </li>
  <li>
    <strong>Format the New Partition:</strong>
    <pre><code>sudo mkfs.ext4 /dev/sdX1</code></pre>
  </li>
  <li>
    <strong>Mount and Persist:</strong>
    <ol>
      <li>Create a mount point:
        <pre><code>sudo mkdir /mnt/storage</code></pre>
      </li>
      <li>Mount the partition:
        <pre><code>sudo mount /dev/sdX1 /mnt/storage</code></pre>
      </li>
      <li>Add the drive to <code>/etc/fstab</code> using its UUID:
        <pre><code>
UUID=$(sudo blkid -s UUID -o value /dev/sdX1)
echo "UUID=$UUID /mnt/storage ext4 defaults 0 0" | sudo tee -a /etc/fstab
        </code></pre>
      </li>
    </ol>
  </li>
  <li>
    <strong>Verify:</strong> Confirm the drive is mounted:
    <pre><code>df -h</code></pre>
    Reboot to ensure the configuration persists.
  </li>
</ol>
     {/* <h1>Mounting a drive on a Linux server</h1>
     <ol>
        <li>Physically connect the new drive to the server. Ensure that it is properly connected and powered on</li>
        <li>Identify the new drive you have just connected by using command <mark>lsblk</mark>, this command will list all available block devices. The drive will typically be listed as /dev/sdX, where X would be a letter indicating the drive. You may need to identify drive by its size.</li>
        <li>Alternatively, you can use fdisk -l to list the partitions of all drives.</li>
        <li>Partition and format the new drive by using the following command: <mark>mkfs.ext4 -j -L DriveName /dev/sdX</mark><br/>
        - mkfs.ext4: is the command to create an ext4 file system. mkfs stands for "make file system," and ext4 refers to the fourth extended file system, which is a commonly used file system in Linux.<br/>
        - '-j': Enables the use of an ext3-style journal for the ext4 file system. The journal helps improve the file system's consistency and recoverability in the event of a system crash or power failure.<br/>
        - '-L': This option is used to specify a label for the file system.<br/>
        - /dev/sdX represents the partition identifier. <br/>
        - Replace X with appropriate letter corresponding to your drive.<br/></li>
        <li>Choose a mount point: Decide where you want to mount the new drive. You can choose an existing empty directory or create a new directory. Example: <mark>sudo mkdir /mnt/newdrive</mark> </li>
        <li>Mount the new drive to the chosen mount point using the 'mount' command: <mark>sudo mount /dev/sdX /mnt/newdrive</mark></li>
        <li>If you want the drive to be automatically mounted at boot, you'll need to add an entry to the /etc/fstab file. Open the file with a text editor(Nano), and add a line similar to the following: <mark>/dev/sdX /mnt/newdrive  ext4  defaults  0  0</mark> <br/>
        - In place of defaults you can also be more specific with your mount options.<br/> Example: <mark>auto nosuid,nodev,nofail,x-gvfs-show,x-gvfs</mark><br/>
        - auto: Indicates that the file system should be automatically mounted at boot time.<br/>
        - nosuid: Prevents the execution of setuid and setgid bits on files within the file system, this can enhance security.<br/>
        - nodev: Disables the execution of device nodes and helps to enhance security by preventing users from creating or accessing device files within the mounted file system.<br/>
        - nofail: Tells the system not to halt the boot process or display error messages if the file system fails to mount. Instead, it continues the boot process, treating the failed mount as non-critical. Better used for non-essential file systems.</li>
     </ol> */}
  </SheetContainer>
  < Footer />
  </>
};

  
  export default Mount;