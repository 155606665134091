import React from "react";
import styled from 'styled-components';
import Footer from '../../components/Footer';


const SheetContainer=styled.section`
    width: 90%;
    border-top: 4px solid yellow;
    text-align: left;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px grey;
    padding: 30px;
    margin: 30px;
    background-color: back;
    li {
      margin: 20px;
      font-size: 20px;
      list-style-type: numbers;
      line-height: 40px;
    }
    h2, h1 {
      text-align: center;
    }
    mark {
      background-color: black;
      border-radius: 6px;
      color: white;
      padding: 7px;
      font-style: italic;
    }
`

const ServerConfig = () => {
    return <>
      
            <SheetContainer>
            <h1>Server Configuration</h1>
              <ul>
              <li>Install net-tools (this includes ifconfig): <mark>apt install net-tools</mark></li>
              <li>Check IP Address: <mark>ifconfig</mark></li>
              <li>If setting up a Static IP address:
                <ol>
                <li>Assuming we are still logged in as root, navigate to the network configuration file- <mark>cd /etc/netplan/00-installer-config.yaml</mark></li>
                <li>Nano (or your prefered text editor) into the .yaml file and modify it with your desired network settings.</li>
                <p>Here is an example of what it should look like:</p>
<p><strong>YAML file:</strong></p>
<pre>
    <code>
{`network:
  ethernets:
    eth10:
      addresses: [static IP Address/subnet mask]
      gateway4: default gateway IP address
      nameservers:
        addresses: [DNS Server 1, DNS Server 2]
  version: 2`}
    </code>
  </pre>
<ul>
  <p>*eth10 represents the network interface, replace eth10 with the interface name on your system.</p>
  <p>*Replace static IP address and subnet mask according to your network.</p>
  <p>*Enter your default gateway IP address following the colon after <code>gateway4</code>.</p>
  <p>*Replace DNS Server 1 and 2 with your preferred DNS Server addresses.</p>
</ul>
<li>Save the changes and exit the text editor.</li>
                <li>Apply the new network configuration by running command: <mark>sudo netplan apply</mark></li>
                <li>Verify your changes with command: <mark>ip address show</mark> you should see IP address associated with network interface</li>    
                </ol>
              </li>
              <li>If Secure ftp is not already installed: 
              <ul>
                <li>Login as root</li>
                <li><mark>sudo su</mark> then paste in root user password</li>
                <li><mark>cd ~</mark>- moves to root user's home directory</li>
                <li><mark>apt-get install vsftpd</mark></li>
                </ul>
                </li>
                </ul>
                <h2>Installing Languages</h2>
                <ul>
              <li> Note: Languages are to be install before PostgreSQL. PG requires the operating
                system to have certain languages available before PG can install its language
                packages. This language is required for some of the database re-builds we will be
                doing later. We usually install pg languages into the template1 database.</li>
              <li>To check languages already installed:
                <ul>
                  <li><mark>sudo su</mark></li>
                  <li><mark>cd ~</mark></li>
                  <li><mark>locale -a</mark>-this returns list of languages.</li>
                  </ul> 
              </li>
              <li>To add language: <ul>
                <li><mark>locale-gen en_CA.UTF-8</mark></li>
                <li>Re-enter <mark>locale -a</mark> to make sure it is added</li>
                <li>Note: You can install other languages using these same or similar steps.</li>
                </ul> 
              </li>
              </ul>
              <h2>Install Apache Web server</h2>
                <ul> 
              <li><mark>apt-get update</mark></li>
              <li><mark>apt-get install apache2</mark></li>
              <li>Install with PHP at the same time: <mark>apt-get install apache2 php libapache2-mod-php</mark></li>
              <li>Check Install in a browser: http://IPaddress/- make sure Apache page displays.</li>
              <li>In the command line check if php is installed by using command: <mark>php -v</mark></li>
            </ul>
            <h2>Installing R</h2>
            <ul>
              <li>Note: Install before PostgreSQL</li>
              <li><mark>apt install r-base-core</mark>-Nearly 250 mb of files to be installed</li>
              <li>Check if R installed: <mark>R</mark>- Version should be indicated</li>
              <li>Reboot the machine with command: <mark>reboot now</mark></li>
              <li>Check back after a few minutes, log back in</li>
            </ul>
            <h2>Installing R Packages</h2>
            <ol>
              <li>Log back in with root user, <mark>sudo su</mark> then <mark>cd ~</mark>to navigate to home directory.</li>
              <li>Navigate to R library path: <mark>cd /usr/local/lib/R/site-library</mark></li>
              <li>Set the value of the environment variable R_LIBS_SITE- <mark>R_LIBS_SITE=$(R_LIBS_SITE:-'/usr/local/lib/R/site-library:/usr/lib/R/site-library:/usr/lib/R/library')</mark><br/>*Replace () with curly brackets</li>
              <li>Move to folder- <mark>cd /usr/local/lib/R/site-library</mark></li>
              <li>Launch the R console entering command: <mark>R</mark></li>
              <li>Inside the R console, you can install packages by using: <mark>install.packages( )</mark></li>
              <li>We chose to install the following-<br/>-<mark>install.packages("gdata", dependencies = TRUE)</mark><br/>
              -<mark>install.packages("reshape2", dependencies = TRUE)</mark><br/>-<mark>install.packages("RSQLite", dependencies = TRUE)</mark></li>
              <li>The following are more options depending on what the server is being used for-<br/>-<mark>install.packages("RPostgreSQL", dependencies = TRUE)</mark><br/>-<mark>install.packages("RStata", dependencies = TRUE)</mark><br/>-<mark>install.packages("RSPS", dependencies = TRUE)</mark><br/>-<mark>install.packages("RMySQL", dependencies = TRUE)</mark><br/>-<mark>install.packages("ggplot2", dependencies = TRUE)</mark></li>
              <li>Quit R using- <mark>q( )</mark><br/>When exiting R you will be asked if you want to Save workspace image (y/n/c)?<br/>
              This simply means at the end of an R session, the user can save an image of the current workspace that is automatically reloaded the next time R is started.</li>
            </ol>
            <h2>Installing PostgreSQL</h2>
            <ol>
              <li>Make sure your logged in as root user then move to home directory.</li>
              <li>Use command: <mark>sudo apt-get install postgresql-all</mark></li>
              <li>Previous command installs newest version of PostgreSQL and all packages, the log files are stored in: /var/log/postgresql/postgresql-14-main.log</li>
              <li>Check install- <mark>systemctl status postgresql</mark><br/> If you see that PG is running, then it was succesful.</li>
              <li>Reboot the machine- <mark>reboot now</mark></li>
            </ol>
            <h2>Adding PG users</h2>
            <ol>
              <li>Log into server as root</li>
              <li>type the following command: <mark>su postgres</mark> You are now on Linux as postgres user.</li>
              <li>Move to the following:<mark>cd /etc/postgresql/14/main</mark></li>
              <li>Use command: <mark>psql</mark> to access postgres interactive shell.</li>
              <li>Create a new user by entering command: <mark>CREATE USER new_username WITH PASSWORD 'password';</mark></li>
              <li>Optionally, you can grant specific privileges to the user by using the following command: <mark>ALTER USER new_username GRANTUSEROPTIONS;</mark><br/>Common options: <ul>
                <li>CREATEDB: Grants the user the ability to create databases.</li>
                <li>CREATEROLE: Grants the user the ability to create roles (users and groups).</li>
                <li>LOGIN: Allows the user to log in to the PostgreSQL server.</li>
                <li>NOCREATEDB: Revokes the user's ability to create databases.</li>
                <li>NOCREATEROLE: Revokes the user's ability to create roles</li>
                <li>NOLOGIN: Prevents the user from logging in.</li>
                <li>INHERIT: Allows the user to inherit privileges from roles it is a member of.</li>
                <li>NOINHERIT: Disallows the user from inheriting privileges.</li>
                <li>CONNECTION LIMIT num: Sets the maximum number of concurrent connections the user can have. Replace num with the desired limit.</li>
                <li>VALID UNTIL 'timestamp': Sets an expiration date/time for the user account. Replace 'timestamp' with the desired date and time in the format 'YYYY-MM-DD HH:MI:SS'.</li>
                <p>**You can combine any combo of the above options.**</p>
                </ul>
                </li>
              <li>To show the users created within psql run command: <mark>\du</mark></li>
              <li>To list schemas use command: <mark>\dn</mark></li>
              <li>To exit psql: <mark>\q</mark></li>
            </ol>
            <h2>PostgreSQL Languages</h2>
            <ul>
            <li>Use <mark>apt search postgresql-16</mark> to find the available languages to install.</li>
            <li>We need postgresql-14-plr - Procedural language interface between PostgreSQL and R</li>
            <li>As well as postgresql-plpython3-12 - PL/Python 3 procedural language for PostgreSQL 14</li>
            <li>Use command: <mark>apt-get install postgresql-plpython3-14</mark></li>
            <li>Use command: <mark>apt-get install postgresql-14-plr</mark> IMPORTANT- must install R first (V4.1.2)</li>
            <li>Then install languages as extensions into the pg template1 database- <mark>su postgres</mark></li>
            <li>Move to folder /var/lib/postgresql/16/main -- Where data for db should be.</li>
            <li>Log into template1 database from command line</li>
            <li>To add plpython3: <mark>CREATE EXTENSION plpython3u SCHEMA pg_catalog;</mark>(cannot be installed into public schema)</li>
            <li>To add plr: <mark>CREATE EXTENSION plr SCHEMA public;</mark></li>
            <li>To verify the extensions have been installed correctly, you can run:<mark>\dx</mark></li>
            <li>Exit psql as postgres user:<mark>\q</mark> </li>
            <li>Return to root user<mark>exit</mark></li>
            <li>Restart PG: <mark>systemctl restart postgresql</mark></li>
            </ul>
            <h2>Settings for PostgreSQL 16</h2>
            <ol>
              <h3>Configuration file changes</h3>
              <li>Log in as root</li>
              <li>Move to correct folder: <mark>cd /etc/postgresql/16/main </mark> </li>
              <li>Check memory size by using command: <mark>free</mark></li>
              <li>Use following command to nano into file for changes needed: <mark>nano postgresql.conf</mark></li>
              <li>Listed below are options of settings to change for improved performance, utilizaton and overall functionality:
                <ul>
                  <li>listen_addresses: Determines which IP addresses or hostnames the server will listen on for incoming connections. You will need to enable this setting and enter either '*' to instruct all or be more specific and enter individual addresses. Example:<mark>listen_addresses = 'localhost, 190.170.1.00'</mark></li>
                  <li>shared_buffers: This setting determines the amount of memory allocated for PostgreSQL. Increasing shared_buffers can improve read performance. A good starting point is typically around 25% to 35% of available memory. example-<mark>shared_buffers = 1028MB</mark></li>
                  <li>work_mem: Determines the amount of memory used for internal sort operations and complex queries. Increasing this value can speed up queries, but it also consumes more memory. You can experiment to find an optimal value for your workload.</li>
                  <li>effective_cache_size: Specifies the estimated size of the operating system's disk cache. It helps PostgreSQL in query planning to determine the optimal execution plan..</li>
                  <li>max_connections: Determines the maximum number of connections allowed at one time to the database server. Set it to the expected number of connections your application requires. Be cautious not to set it too high, as it would effect system resources.</li>
                  <li>autovacuum: Enable and configure autovacuum settings to automatically manage the removal of dead rows and the optimization of table statistics.You will also have to enable track_counts as it is required when autovacuum is set to on. Example- <mark>autovacuum = on <br/> track_counts = on</mark></li>
                </ul>
              </li>
              <li>After applying your desired changes to the file, restart Postgres: <mark>/etc/init.d/postgresql restart</mark>or <mark>service postgresql restart</mark></li>
              <li>Next you can nano into the pg_hba.conf file. This file determines which users are allowed to connect to the database and from which IP addresses or hosts they can connect.</li>
              <li>This file follows a specific structure, each line represents a rule for client authentication. <br/>Format:&nbsp; <mark>TYPE&nbsp;&nbsp;  DATABASE&nbsp;&nbsp;  USER&nbsp;&nbsp;  ADDRESS&nbsp;&nbsp;  METHOD&nbsp;&nbsp;  [OPTIONS]</mark><br/>Example:<mark>host&nbsp;&nbsp;&nbsp;&nbsp; mydb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; all&nbsp;&nbsp;&nbsp;&nbsp;    180.190.0.0/24&nbsp;&nbsp;    md5</mark> </li>
              <li>After adding the necessary lines exit nano <mark>Ctrl+x</mark></li>
              <li>Restart PG <mark>/etc/init.d/postgresql restart</mark></li>
            </ol>
            <h2>Enabling Encrypted HTTPS Connections on Websites</h2>
            <h3>Using LetsEncrypt</h3>
            <ol>
              <li>Go to site: <a href="https://letsencrypt.org/" target="blank">https://letsencrypt.org/</a></li>
              <li>Click Get Started</li>
              <li>Click Certbot ACME client</li>
              <li>Change the two drop down menus beside 'My HTTP website is running' to the software and system you are using for your website</li>
              <li>Default instructions will appear underneath</li>
              <li>Follow the simple steps given on the site</li>
              <li>You will be asked certain questions on the command line and which specific sites you'd like to activate HTTPS for</li>
              <li>After going through all the suggested steps,  check your website to see if HTTPS was enabled.</li>
            </ol>
            <h2>Install pgAdmin4</h2>
            <ol>
              <li>Visit- <a href="https://www.pgadmin.org/download/pgadmin-4-apt/" target="blank"> https://www.pgadmin.org/download/pgadmin-4-apt/</a></li>
              <li>Setup the repository: follow the two steps listed.</li>
              <li>Install pgAdmin: options for desktop only or web only are listed, choose one that best suits what you need pgAdmin4 for</li>
              <li>Configure the webserver if you installed the web version.</li>
              <li>You will then be asked to enter email and password of admin user for first login.</li>
              <li>Then it will ask you to restart the Apache webserver</li>
              <li>Navigate to your site /pgadmin4 enter credentials and from there you can add any additional users and register your server  within pgAdmin</li>
              </ol>
            </SheetContainer>
            <Footer/>
    </>
  };
  
  export default ServerConfig;